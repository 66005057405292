<script setup lang="ts">
import {
  computed,
  ComputedRef,
  onMounted,
  provide,
  reactive,
  ref,
  UnwrapNestedRefs,
} from 'vue';
import SwProductListingFilter from './SwProductListingFilter.vue';
import {
  CmsElementProductListing,
  CmsElementSidebarFilter,
  useListing,
} from '@shopware-pwa/composables-next';
import { ShopwareSearchParams } from '@shopware-pwa/types';
import deepMerge from '../helpers/deepMerge';
import getTranslations from '../helpers/getTranslations';
import { Button, Loader } from '@upa/design-system';
// import  ListingFilter  from '../components/listing-filters/ListingFilter.vue'
import { Modal, Icon } from '@upa/design-system';
import { OcProductFilterSidebar } from '@upa/design-system';
import { Search } from '@upa/design-system';

const { isProfessionalStore } = useStore();
// import { ListingResult, Product } from "@shopware-pwa/types";

// const { searchTerm, search, getProducts, getTotal, loading } =
//   useProductSearchSuggest();

const props = defineProps<{
  content: CmsElementProductListing | CmsElementSidebarFilter;
  listingType?: string;
}>();

type Translations = {
  listing: {
    sort: string;
    resetFilters: string;
  };
};

let translations: Translations = {
  listing: {
    sort: 'Sort',
    resetFilters: 'Reset filters',
  },
};

const globalTranslations = getTranslations();
translations = deepMerge(translations, globalTranslations) as Translations;

const { category } = useCategory();
const route = useRoute();
const router = useRouter();

const isSortMenuOpen = ref(true);
const {
  search: searchListing,
  getCurrentSortingOrder,
  changeCurrentSortingOrder,
  getSortingOrders,
  getInitialFilters,
  getCurrentFilters,
  filtersToQuery,
  setInitialListing,
  getElements: products,
} = useListing({ listingType: 'categoryListing' });

const showSorting = getSortingOrders?.value?.length > 1;

const { search, getElements, getCurrentListing, setCurrentFilters } =
  useListing({ listingType: 'productSearchListing' });

const productItems: any = [];

const searchProducts = computed(() => {
  products.value?.forEach((element: any) => {
    productItems.push({
      id: element.id,
      productName: element.translated?.name,
      superTitle: element.superTitle,
    });
  });
  return productItems;
});

const sidebarSelectedFilters: UnwrapNestedRefs<{
  [key: string]: any;
}> = reactive<{
  manufacturer: Set<any>;
  properties: Set<any>;
  'min-price': string | number | undefined;
  'max-price': string | number | undefined;
  rating: string | number | undefined;
  'shipping-free': boolean | undefined;
}>({
  manufacturer: new Set(),
  properties: new Set(),
  'min-price': undefined,
  'max-price': undefined,
  rating: undefined,
  'shipping-free': undefined,
});

const searchCriteriaForRequest: ComputedRef<{
  [code: string]: string | string[] | number | number[] | boolean | undefined;
}> = computed(() => ({
  // turn Set to array and then into string with | separator
  manufacturer: [...sidebarSelectedFilters.manufacturer]?.join('|'),
  // turn Set to array and then into string with | separator
  properties: [...sidebarSelectedFilters.properties]?.join('|'),
  'min-price': sidebarSelectedFilters['min-price'],
  'max-price': sidebarSelectedFilters['max-price'],
  order: getCurrentSortingOrder.value,
  'shipping-free': sidebarSelectedFilters['shipping-free'],
  rating: sidebarSelectedFilters['rating'],
}));

for (const param in route.query) {
  if (sidebarSelectedFilters.hasOwnProperty(param)) {
    if (
      sidebarSelectedFilters[param] &&
      typeof sidebarSelectedFilters[param] === 'object'
    ) {
      (route.query[param] as unknown as string)
        .split('|')
        .forEach((element) => {
          sidebarSelectedFilters[param].add(element);
        });
    } else {
      sidebarSelectedFilters[param] = route.query[param];
    }
  }
}

const onOptionSelectToggle = async ({
  code,
  value,
}: {
  code: string;
  value: any;
}) => {
  if (!['properties', 'manufacturer'].includes(code)) {
    sidebarSelectedFilters[code] = value;
  } else {
    if (sidebarSelectedFilters[code]?.has(value)) {
      sidebarSelectedFilters[code]?.delete(value);
    } else {
      sidebarSelectedFilters[code]?.add(value);
    }
  }

  await searchListing(searchCriteriaForRequest.value);
  await router.push({
    query: { ...filtersToQuery(searchCriteriaForRequest.value) },
  });
};

const queryString = ref('');

// const onSearch = async (query: string) => {

// };

const searchQuery = async (query: string) => {
  queryString.value = query;

  if (queryString.value.length >= 3) {
    await search({
      limit: 20,
      p: 1,
      query: queryString.value,
      properties: route.query.properties,
    } as Partial<ShopwareSearchParams>);

    //setInitialSearchListing(searchResult.value.elements)
  }
};

watch(queryString, (value) => {
  if (value.length >= 3) {
    performSuggestSearch(value);
  }
});

// Defer the search request to prevent the search from being triggered too after typing
const performSuggestSearch = useDebounceFn((value) => {
  // console.log(value);
  // searchTerm.value = value;
  // search();
}, 300);

/**
 * SEARCH
 */
const showAllResults = async () => {
  await router.push({
    path: route.fullPath,
    query: {
      ...route.query,
      query: queryString.value,
    },
  });

  await search({
    limit: 20,
    p: 1,
    query: queryString.value,
    properties: route.query.properties,
  } as Partial<ShopwareSearchParams>);

  if (getCurrentListing.value) {
    setInitialListing(getCurrentListing.value);
  }
  //setCurrentFilters();
};

/**
 * FILTER
 */

const clearFilters = async () => {
  sidebarSelectedFilters.manufacturer.clear();
  sidebarSelectedFilters.properties.clear();
  sidebarSelectedFilters['min-price'] = undefined;
  sidebarSelectedFilters['max-price'] = undefined;
  sidebarSelectedFilters['rating'] = undefined;
  sidebarSelectedFilters['shipping-free'] = undefined;
  searchListing(searchCriteriaForRequest.value);
  await router.push({
    query: { ...filtersToQuery(searchCriteriaForRequest.value) },
  });
};

const currentSortingOrder = computed({
  get: (): string => getCurrentSortingOrder.value || '',
  set: async (order: string): Promise<void> => {
    await router.push({
      query: {
        ...route.query,
        order,
      },
    });

    changeCurrentSortingOrder(order, route.query);
  },
});

const selectedOptionIds = computed(() => [
  ...sidebarSelectedFilters.properties,
  ...sidebarSelectedFilters.manufacturer,
]);
provide('selectedOptionIds', selectedOptionIds);

async function invokeCleanFilters() {
  await searchListing({});
  clearFilters();
}

let opened1 = ref(false);
let toggleModal = () => {
  opened1.value = !opened1.value;
};

let closeFilterModal = () => {
  opened1.value = false;
};

onMounted(() => {
  const element = dropdownElement.value;
});

// TODO: on initial load misses  ref dropdownElement
const dropdownElement = ref(null);
onClickOutside(dropdownElement, () => (isSortMenuOpen.value = false));

const collectSortingOrders = ref(getSortingOrders.value);
let currentSortLabel = ref(''); // Declare currentSortLabel outside the if block

watchEffect(() => {
  const sortingObject = collectSortingOrders.value.find(
    (obj) => obj.key === getCurrentSortingOrder.value,
  );

  if (sortingObject) {
    currentSortLabel.value = sortingObject.label;
  }
});

/**
 * TODO: Find adequate (backend) solution and replace the temporary hack below to hide SW standard filters
 */
const initialFilters = computed(() => getInitialFilters.value);
const filtersToBeHiddenById = isProfessionalStore
  ? []
  : ['416f443defbfbd074117efbfbd0c295c', '018c20895b7073f7a6f64e5dcab88a05'];
</script>

<template>
  <ClientOnly>
    <div class="bg-white">
      <main class="product-listing-filters">
        <div class="filter-sidebar-trigger">
          <Button :primary="true" @click="toggleModal">
            {{ $t('listing.filter') }}
          </Button>
          <Modal
            v-if="opened1"
            class="oc-sidebar__modal oc-sidebar__modal_left"
            :title="$t('listing.modalTitle')"
            @layer-click="closeFilterModal"
            @escape-press="closeFilterModal"
            @close="closeFilterModal"
          >
            <template #content>
              <!-- //TODO: rewrite component ocProductFilterSidebar and use it twice here to avoid the code repetition -->
              <!-- <div>SUCHE</div> -->
              <div v-if="initialFilters.length" class="flex flex-wrap">
                <div class="fiter-sidebar__search-container">
                  <Search
                    :search-preview="true"
                    :product-list="searchProducts"
                    :search-placeholder="$t('listing.search.placeholder')"
                    @search="showAllResults"
                    @go-to-result="showAllResults"
                    @search-query-input="searchQuery"
                  />
                </div>
                <div
                  v-for="filter in initialFilters.filter((item) => item.id)"
                  :key="`${filter?.id || filter?.code}`"
                  :class="{
                    'hide-filters': filtersToBeHiddenById.includes(filter.id),
                  }"
                  class="mb-2 w-full"
                >
                  <SwProductListingFilter
                    :selected-filters="getCurrentFilters"
                    :filter="filter"
                    class="relative"
                    @selectFilterValue="onOptionSelectToggle"
                  />
                </div>
                <Button
                  type="button"
                  :primary="true"
                  :full-width="true"
                  @click="invokeCleanFilters"
                >
                  {{ $t('listing.resetFilters') }}
                </Button>

                <div
                  v-if="showSorting"
                  ref="dropdownElement"
                  class="sorting-select-mobile"
                  style="width: 100%; position: relative"
                >
                  <div>
                    <button
                      id="menu-button"
                      type="button"
                      style="
                        height: 76px;
                        width: 100%;
                        border-bottom: 1px solid #8d8f9a;
                        padding-left: 16px;
                        margin-top: 24px;
                        position: relative;
                      "
                      :style="{
                        backgroundColor: isSortMenuOpen ? 'white' : '#f1f2f3',
                        boxShadow: isSortMenuOpen
                          ? 'rgba(0, 0, 0, 0.25) 0px 0px 12px'
                          : 'none',
                      }"
                      aria-expanded="false"
                      aria-haspopup="true"
                      @click="isSortMenuOpen = !isSortMenuOpen"
                    >
                      <div
                        style="display: flex; font-size: 14px"
                        :style="{
                          backgroundColor: isSortMenuOpen ? 'white' : '#f1f2f3',
                        }"
                      >
                        {{ translations.listing.sort }}
                      </div>
                      <div style="display: flex; font-size: 16px">
                        <!-- {{ getCurrentSortingOrder }} -->
                        {{ currentSortLabel }}
                        <div
                          style="
                            justify-self: flex-end;
                            margin-left: auto;
                            margin-right: 16px;
                          "
                        >
                          <Icon
                            name="chevron_down"
                            :size="400"
                            :class="{ hidden: isSortMenuOpen }"
                          />
                          <Icon
                            name="chevron_up"
                            :size="400"
                            :class="{ hidden: !isSortMenuOpen }"
                          />
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    :class="[isSortMenuOpen ? 'relative' : 'hidden']"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabindex="-1"
                    style="width: 100%"
                  >
                    <div
                      role="none"
                      style="
                        background-color: white;
                        width: 100%;
                        z-index: 1;
                        box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 12px;
                      "
                    >
                      <button
                        v-for="sorting in getSortingOrders"
                        :key="sorting.key"
                        style="
                          width: 100%;
                          text-align: left;
                          padding: 8px;
                          border-bottom: 1px solid #dbdbdb;
                          background-color: white;
                          font-size: 12px;
                        "
                        :style="{
                          color:
                            sorting.key === getCurrentSortingOrder
                              ? '#da202c'
                              : '#72757e',
                        }"
                        role="menuitem"
                        tabindex="-1"
                        @click="
                          currentSortingOrder = sorting.key;
                          isSortMenuOpen = false;
                        "
                      >
                        {{ sorting.label }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </Modal>
        </div>
        <!-- //TODO: rewrite component ocProductFilterSidebar and use it twice here to avoid the code repetition -->
        <div v-if="initialFilters.length" class="flex flex-wrap filter-desktop">
          <div>
            <Search
              :search-preview="true"
              :product-list="searchProducts"
              :search-placeholder="$t('listing.search.placeholder')"
              @search="showAllResults"
              @go-to-result="showAllResults"
              @search-query-input="searchQuery"
            />

            <!-- :product-list="(searchProducts as any)" -->
          </div>

          <div
            v-for="filter in initialFilters.filter((item) => item.id)"
            :key="`${filter?.id || filter?.code}`"
            :class="{
              'hide-filters': filtersToBeHiddenById.includes(filter.id),
            }"
            class="mb-2 w-full"
          >
            <SwProductListingFilter
              :selected-filters="getCurrentFilters"
              :filter="filter"
              class="relative"
              @selectFilterValue="onOptionSelectToggle"
            />
          </div>
          <Button
            class="menu-button__filter"
            :outline="true"
            :icon-name="'close'"
            :content="$t('listing.resetFilters')"
            @click="invokeCleanFilters"
          />
        </div>
        <div
          v-if="showSorting"
          ref="dropdownElement"
          class="sorting-select-desktop"
          style="position: relative"
        >
          <div>
            <button
              id="menu-button"
              type="button"
              class="menu-button__sorting"
              aria-expanded="false"
              aria-haspopup="true"
              @click="isSortMenuOpen = !isSortMenuOpen"
            >
              <div style="display: flex; font-size: 14px">
                {{ translations.listing.sort }}
              </div>
              <div style="display: flex; font-size: 16px">
                {{ currentSortLabel }}
                <div
                  style="
                    justify-self: flex-end;
                    margin-left: auto;
                    margin-right: 16px;
                  "
                >
                  <Icon
                    name="chevron_down"
                    :size="400"
                    :class="{ hidden: isSortMenuOpen }"
                  />
                  <Icon
                    name="chevron_up"
                    :size="400"
                    :class="{ hidden: !isSortMenuOpen }"
                  />
                </div>
              </div>
            </button>
          </div>
          <div
            :class="[isSortMenuOpen ? 'relative' : 'hidden']"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabindex="-1"
          >
            <div
              role="none"
              style="
                background-color: white;
                width: 100%;
                z-index: 1;
                box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 12px;
              "
            >
              <button
                v-for="sorting in getSortingOrders"
                :key="sorting.key"
                style="
                  width: 100%;
                  text-align: left;
                  padding: 8px;
                  border-bottom: 1px solid #dbdbdb;
                  background-color: white;
                  font-size: 12px;
                "
                :style="{
                  color:
                    sorting.key === getCurrentSortingOrder
                      ? '#da202c'
                      : '#72757e',
                }"
                role="menuitem"
                tabindex="-1"
                @click="
                  currentSortingOrder = sorting.key;
                  isSortMenuOpen = false;
                "
              >
                {{ sorting.label }}
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>
    <template #placeholder>
      <div class="bg-white">
        <main class="mx-auto">
          <div style="height: 400px">
            <div
              class="relative flex items-center justify-around pt-6 pb-6"
              style="height: 100%"
            >
              <Loader :loading="true" />
            </div>
            <div
              v-show="!getInitialFilters.length"
              class="flex flex-wrap"
            ></div>
          </div>
        </main>
      </div>
    </template>
  </ClientOnly>
</template>

<style scoped lang="scss">
.fiter-sidebar__search-container {
  width: 100%;
}

.product-listing-filters {
  @media (min-width: 1024px) {
    padding-bottom: 100px;
  }

  .hide-filters {
    display: none;
  }
}
.filter-sidebar-trigger {
  display: inline-block;
  max-width: fit-content;
  position: absolute;
  right: 16px;
  margin-top: 24px;

  @media (min-width: 1024px) {
    display: none;
  }
}

.sorting-select-desktop {
  display: none;

  @media (min-width: 1024px) {
    display: block;
  }
}

.filter-desktop {
  display: none;

  @media (min-width: 1024px) {
    display: block;
  }
}

.button__wrapper {
  width: 100%;
}

:deep(.modal__container) {
  padding: 16px;
}

:deep(.modal__content) {
  margin-top: 16px;
}

.menu-button__sorting {
  position: relative;
  height: 76px;
  width: 100%;
  border: 1px solid #8d8f9a;
  border-radius: 6px;
  padding-left: 16px;
  margin-top: 24px;
  background-color: var(--_c-white-primary);

  .icon {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }
}

.menu-button__filter {
  :deep(.button) {
    width: 100%;
  }
}
</style>
